// @mui
import PropTypes from 'prop-types';
import React, { useState } from 'react';
// @mui
import { List, Collapse, Box, ListItemText, Skeleton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useList } from '@motor-js/engine';
import { ListItemStyle, ListItemTextStyle, ListItemIconStyle } from '../nav-section/vertical/style';
import Iconify from '../Iconify';
import Label from '../Label';
// ----------------------------------------------------------------------

Filter.propTypes = {
  isCollapse: PropTypes.bool,
  filter: PropTypes.object,
};

export default function Filter({ isCollapse, filter }) {
  const [open, onOpen] = useState(false);
  const { title, dimension, icon, lock, singleSelect } = filter;
  const theme = useTheme();

  const { listData, select, selections } = useList({
    dimension: [dimension],
    qPage: { qHeight: 100 },
    autoSortByState: false,
  });

  return (
    <>
      {listData ? (
        <>
          <ListItemStyle onClick={() => onOpen(!open)}>
            {isCollapse && (
              <ListItemIconStyle>
                <Iconify sx={{ color: selections.length > 0 && theme.palette.primary.main }} icon={icon} />
              </ListItemIconStyle>
            )}

            <ListItemTextStyle disableTypography primary={title} isCollapse={isCollapse} />

            {lock && !isCollapse && (
              <ListItemIconStyle sx={{ mr: 1 }}>
                <Iconify icon={'eva:lock-fill'} />
              </ListItemIconStyle>
            )}

            {selections.length > 0 && !isCollapse && (
              <Label variant="ghost" color="primary">
                {selections.length}
              </Label>
            )}

            <ArrowIcon open={open} />
          </ListItemStyle>

          <Collapse in={open} timeout="auto" unmountOnExit sx={{ maxHeight: '300px', overflowY: 'scroll' }}>
            <List component="div" disablePadding>
              {listData.map((item) => (
                <ListItemStyle subItem key={item.key} onClick={() => select([item.key], !(lock || singleSelect))}>
                  <DotIcon active={item.state === 'S'} />

                  <ListItemText
                    disableTypography
                    primary={item.text}
                    sx={{ fontWeight: item.state === 'S' && 'bold' }}
                  />
                </ListItemStyle>
              ))}
            </List>
          </Collapse>
        </>
      ) : (
        <Skeleton height={60} />
      )}
    </>
  );
}

// ----------------------------------------------------------------------

DotIcon.propTypes = {
  active: PropTypes.bool,
};

export function DotIcon({ active }) {
  return (
    <ListItemIconStyle>
      <Box
        component="span"
        sx={{
          width: 4,
          height: 4,
          borderRadius: '50%',
          bgcolor: 'text.disabled',
          transition: (theme) =>
            theme.transitions.create('transform', {
              duration: theme.transitions.duration.shorter,
            }),
          ...(active && {
            transform: 'scale(2)',
            bgcolor: 'primary.main',
          }),
        }}
      />
    </ListItemIconStyle>
  );
}

// ----------------------------------------------------------------------

ArrowIcon.propTypes = {
  open: PropTypes.bool,
};

export function ArrowIcon({ open }) {
  return (
    <Iconify
      icon={open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'}
      sx={{ width: 16, height: 16, ml: 1 }}
    />
  );
}
