import PropTypes from 'prop-types';
import merge from 'lodash/merge';
import ReactApexChart from 'react-apexcharts';
// @mui
import { useTheme, styled } from '@mui/material/styles';
import { Card, Typography, Box, Skeleton } from '@mui/material';
import { useData } from '@motor-js/engine';

// utils
import { fValueVolume } from '../../utils/formatNumber';

import { LinearProgressColor } from '../../utils/formatBarColor';
import { BaseOptionChart } from '../chart';

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  alignItems: 'center',
  padding: theme.spacing(3),
  backgroundColor: 'white',
}));

// ----------------------------------------------------------------------

GaugeKPI.propTypes = {
  title: PropTypes.string.isRequired,
  qMetrics: PropTypes.array.isRequired,
  config: PropTypes.object.isRequired,
};

export default function GaugeKPI({ title, qMetrics, config }) {
  const theme = useTheme();

  const { metrics } = useData({
    qMetrics,
  });

  const { gaugeDescription } = config;

  // LinearProgressColor(Math.round(metrics.variance * 100), title.toLowerCase().includes('pending')).hex

  const chartOptions = merge(BaseOptionChart(), {
    chart: { sparkline: { enabled: true } },
    colors: [
      LinearProgressColor(Math.round(metrics && metrics.variance * 100), title.toLowerCase().includes('pending')).hex,
    ],
    legend: { show: false },
    plotOptions: {
      radialBar: {
        hollow: { size: '78%' },
        track: { margin: 0 },
        dataLabels: {
          name: { show: false },
          value: {
            offsetY: 6,
            fontSize: theme.typography.subtitle2.fontSize,
          },
        },
      },
    },
  });

  return (
    <RootStyle
      sx={{
        bgcolor: 'white',
      }}
    >
      <Box sx={{ mr: 3 }}>
        <Typography variant="body2" sx={{ opacity: 0.72, minWidth: 30 }}>
          {metrics ? title : <Skeleton />}
        </Typography>
        <Typography variant="h4">
          {' '}
          {metrics ? fValueVolume(metrics.kpi, metrics.metric === 'Value') : <Skeleton />}{' '}
        </Typography>
      </Box>

      {metrics ? (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <ReactApexChart
            type="radialBar"
            series={[Math.round(metrics.variance * 100)]}
            options={chartOptions}
            width={86}
            height={86}
          />
          <Typography component="div" align="center" variant="caption">
            {gaugeDescription}
          </Typography>
        </Box>
      ) : (
        <Skeleton width={86} height={86} />
      )}
    </RootStyle>
  );
}
