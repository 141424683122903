export const peformance = [
  {
    type: 'kpigauge',
    title: 'Ordered',
    config: { gaugeDescription: 'of Gross Ord.' },
    md: 3,
    xs: 12,
    qMetrics: [
      {
        qExpr: '=[Net Ordered]/[Gross Ordered]',
        qName: 'variance',
        qNumType: 'M',
        dType: 'qValueExpression',
      },
      {
        qExpr: '=[Net Ordered]',
        qName: 'kpi',
        dType: 'qValueExpression',
      },
      {
        qExpr: '=only(metric)',
        qName: 'metric',
        qType: 'qStringExpression',
      },
      {
        qExpr: "='of gross ordered'",
        qName: 'varianceText',
        qType: 'qStringExpression',
      },
    ],
  },
  {
    type: 'kpigauge',
    title: 'Pending',
    config: { gaugeDescription: 'of Net Ord.' },
    md: 3,
    xs: 12,
    qMetrics: [
      {
        qExpr: '=([Pending])/[Net Ordered]',
        qName: 'variance',
        qNumType: 'M',
        dType: 'qValueExpression',
      },
      {
        qExpr: '=[Pending]',
        qName: 'kpi',
        dType: 'qValueExpression',
      },
      {
        qExpr: '=only(metric)',
        qName: 'metric',
        qType: 'qStringExpression',
      },
      {
        qExpr: "='of net ordered'",
        qName: 'varianceText',
        qType: 'qStringExpression',
      },
    ],
  },
  {
    type: 'kpigauge',
    title: 'Received',
    config: { gaugeDescription: 'of Net Ord.' },
    md: 3,
    xs: 12,
    qMetrics: [
      {
        qExpr: '=[Received]/[Net Ordered]',
        qName: 'variance',
        qNumType: 'M',
        dType: 'qValueExpression',
      },
      {
        qExpr: '=[Received]',
        qName: 'kpi',
        dType: 'qValueExpression',
      },
      {
        qExpr: '=only(metric)',
        qName: 'metric',
        qType: 'qStringExpression',
      },
      {
        qExpr: "='of net ordered'",
        qName: 'varianceText',
        qType: 'qStringExpression',
      },
    ],
  },
  {
    type: 'kpigauge',
    title: 'Shipped',
    md: 3,
    xs: 12,
    config: { gaugeDescription: 'of Net Ord.' },
    qMetrics: [
      {
        qExpr: '=[Shipped]/[Net Ordered]',
        qName: 'variance',
        qNumType: 'M',
        dType: 'qValueExpression',
      },
      {
        qExpr: '=[Shipped]',
        qName: 'kpi',
        dType: 'qValueExpression',
      },
      {
        qExpr: '=only(metric)',
        qName: 'metric',
        qType: 'qStringExpression',
      },
      {
        qExpr: "='of received'",
        qName: 'varianceText',
        qType: 'qStringExpression',
      },
    ],
  },

  {
    type: 'table',
    title: 'Performance by Season',
    md: 12,
    xs: 12,
    qPage: { qWidth: 20, qHeight: 500 },
    initialSortKey: 'NetOrdered',
    initialColumnsToExclude: ['metric', 'NetOrderedMix', 'ShippedMix', 'ReceivedMix'],
    options: {
      coreColumns: ['Function'],
      alwaysHide: ['metric'],
      dropdown: [
        { name: 'Actual', hideColumns: ['NetOrderedMix', 'ShippedMix', 'ReceivedMix'] },
        {
          name: 'Mix',
          hideColumns: [
            'NetOrdered',
            'NetOrdered_percbar',
            'NetOrdered',
            'Pending',
            'Pending_percbar',
            'Received',
            'Received_percbar',
            'Shipped',
            'Shipped_percbar',
          ],
        },
      ],
    },
    cols: [
      {
        qField: ['[Sales Order Collection]'],
        dataKey: 'Season',
        qLabel: 'Season',
        qGrouping: 'H',
      },
      {
        qField: '=[Net Ordered]',
        dataKey: 'NetOrdered',
        qLabel: 'Net Ordered',
      },
      {
        qField: '=[Net Ordered]/[Gross Ordered]',
        dataKey: 'NetOrdered_percbar',
        qLabel: '% of Gross',
      },
      {
        qField: '=[Net Ordered] - Received',
        dataKey: 'Pending',
        qLabel: 'Pending',
      },
      {
        qField: '=[Pending]/[Net Ordered]',
        dataKey: 'Pending_percbar',
        qLabel: '% of Net',
      },
      {
        qField: '=[Received]',
        dataKey: 'Received',
        qLabel: 'Received',
      },
      {
        qField: '=[Received]/[Net Ordered]',
        dataKey: 'Received_percbar',
        qLabel: '% of Net',
      },
      {
        qField: '=[Shipped]',
        dataKey: 'Shipped',
        qLabel: 'Shipped',
      },
      {
        qField: '=[Shipped]/[Net Ordered]',
        dataKey: 'Shipped_percbar',
        qLabel: '% of Net',
      },

      {
        qField: "=if(metric='Value',1,0)",
        dataKey: 'metric',
        qLabel: 'metric',
      },
      {
        qField: '=[Net Ordered Mix]',
        dataKey: 'NetOrderedMix',
        qLabel: 'Net Ordered Mix',
      },
      {
        qField: '=[ReceivedMix]',
        dataKey: 'ReceivedMix',
        qLabel: 'Received Mix',
      },
      {
        qField: '=[ShippedMix]',
        dataKey: 'ShippedMix',
        qLabel: 'Shipped Mix',
      },
    ],
  },

  {
    type: 'table',
    title: 'Performance by Client Type',
    md: 12,
    xs: 12,
    qPage: { qWidth: 20, qHeight: 500 },
    initialSortKey: 'NetOrdered',
    initialColumnsToExclude: ['metric', 'NetOrderedMix', 'ShippedMix', 'ReceivedMix'],
    options: {
      coreColumns: ['Function'],
      alwaysHide: ['metric'],
      dropdown: [
        { name: 'Actual', hideColumns: ['NetOrderedMix', 'ShippedMix', 'ReceivedMix'] },
        {
          name: 'Mix',
          hideColumns: [
            'NetOrdered',
            'NetOrdered_percbar',
            'NetOrdered',
            'Pending',
            'Pending_percbar',
            'Received',
            'Received_percbar',
            'Shipped',
            'Shipped_percbar',
          ],
        },
      ],
    },
    cols: [
      {
        qField: ['[Customer Group]', '[Customer Sub Group]', '[Customer Name]'],
        dataKey: 'Customer Type',
        qLabel: 'Customer Type',
        qGrouping: 'H',
      },
      {
        qField: '=[Net Ordered]',
        dataKey: 'NetOrdered',
        qLabel: 'Net Ordered',
      },
      {
        qField: '=[Net Ordered]/[Gross Ordered]',
        dataKey: 'NetOrdered_percbar',
        qLabel: '% of Gross',
      },
      {
        qField: '=Pending',
        dataKey: 'Pending',
        qLabel: 'Pending',
      },
      {
        qField: '=Pending/[Net Ordered]',
        dataKey: 'Pending_percbar',
        qLabel: '% of Net',
      },
      {
        qField: '=[Received]',
        dataKey: 'Received',
        qLabel: 'Received',
      },
      {
        qField: '=[Received]/[Net Ordered]',
        dataKey: 'Received_percbar',
        qLabel: '% of Net',
      },
      {
        qField: '=[Shipped]',
        dataKey: 'Shipped',
        qLabel: 'Shipped',
      },
      {
        qField: '=[Shipped]/[Net Ordered]',
        dataKey: 'Shipped_percbar',
        qLabel: '% of Net',
      },

      {
        qField: "=if(metric='Value',1,0)",
        dataKey: 'metric',
        qLabel: 'metric',
      },

      {
        qField: '=[Net Ordered Mix]',
        dataKey: 'NetOrderedMix',
        qLabel: 'Net Ordered Mix',
      },
      {
        qField: '=[ReceivedMix]',
        dataKey: 'ReceivedMix',
        qLabel: 'Received Mix',
      },
      {
        qField: '=[ShippedMix]',
        dataKey: 'ShippedMix',
        qLabel: 'Shipped Mix',
      },
    ],
  },

  {
    type: 'table',
    title: 'Performance by Function',
    md: 12,
    xs: 12,
    qPage: { qWidth: 20, qHeight: 500 },
    initialSortKey: 'NetOrdered',
    initialColumnsToExclude: ['metric', 'NetOrderedMix', 'ShippedMix', 'ReceivedMix'],
    options: {
      coreColumns: ['Function'],
      alwaysHide: ['metric'],
      dropdown: [
        { name: 'Actual', hideColumns: ['NetOrderedMix', 'ShippedMix', 'ReceivedMix'] },
        {
          name: 'Mix',
          hideColumns: [
            'NetOrdered',
            'NetOrdered_percbar',
            'NetOrdered',
            'Pending',
            'Pending_percbar',
            'Received',
            'Received_percbar',
            'Shipped',
            'Shipped_percbar',
          ],
        },
      ],
    },
    cols: [
      {
        qField: ['[Function]', '[Line]', "=Item &  'SPLIT'  & Capitalize([Item Description]) &  'SPLIT' & [URL]"],
        dataKey: 'Function',
        qLabel: 'Function',
        qGrouping: 'H',
      },
      {
        qField: '=[Net Ordered]',
        dataKey: 'NetOrdered',
        qLabel: 'Net Ordered',
      },
      {
        qField: '=[Net Ordered]/[Gross Ordered]',
        dataKey: 'NetOrdered_percbar',
        qLabel: '% of Gross',
      },
      {
        qField: '=[Pending]',
        dataKey: 'Pending',
        qLabel: 'Pending',
      },
      {
        qField: '=[Pending]/[Net Ordered]',
        dataKey: 'Pending_percbar',
        qLabel: '% of Net',
      },
      {
        qField: '=[Received]',
        dataKey: 'Received',
        qLabel: 'Received',
      },
      {
        qField: '=[Received]/[Net Ordered]',
        dataKey: 'Received_percbar',
        qLabel: '% of Net',
      },
      {
        qField: '=[Shipped]',
        dataKey: 'Shipped',
        qLabel: 'Shipped',
      },

      {
        qField: '=[Shipped]/[Net Ordered]',
        dataKey: 'Shipped_percbar',
        qLabel: '% of Net',
      },

      {
        qField: "=if(metric='Value',1,0)",
        dataKey: 'metric',
        qLabel: 'metric',
      },
      {
        qField: '=[Net Ordered Mix]',
        dataKey: 'NetOrderedMix',
        qLabel: 'Net Ordered Mix',
      },
      {
        qField: '=[ReceivedMix]',
        dataKey: 'ReceivedMix',
        qLabel: 'Received Mix',
      },
      {
        qField: '=[ShippedMix]',
        dataKey: 'ShippedMix',
        qLabel: 'Shipped Mix',
      },
    ],
  },
];

// {
//   qField: '=[ShippedMix]',
//   dataKey: 'ShippedMix',
//   qLabel: 'Shipped Mix',
// },

export const filters = [
  {
    title: 'metric',
    dimension: 'metric',
    icon: 'eva:pricetags-fill',
    lock: true,
  },
  {
    title: 'Brand',
    dimension: '[Società]',
    icon: 'whh:design',
  },
  {
    title: 'Season',
    dimension: '[Sales Order Collection]',
    icon: 'eva:sun-fill',
  },
  {
    title: 'Customer Group',
    dimension: '[Customer Group]',
    icon: 'eva:home-fill',
  },
  {
    title: 'Gender',
    dimension: '[Gender Desc]',
    icon: 'eva:person-fill',
  },
  {
    title: 'Function',
    dimension: '[Function]',
    icon: 'mdi:tshirt-crew',
  },
];
