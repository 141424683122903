import React from 'react';
import PropTypes from 'prop-types';
// @mui
import { Box, ModalUnstyled } from '@mui/material';
import { useTable, useButton } from '@motor-js/engine';
import { styled } from '@mui/material/styles';

// components
import { SkeletonProductItem } from '../skeleton';
//
import Card from './Card';
import Table from './Table';

// ----------------------------------------------------------------------

CardGroup.propTypes = {
  config: PropTypes.object.isRequired,
};

const StyledModal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Backdrop = styled('div')`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
`;

const style = {
  width: '75vw',
  bgcolor: 'background.paper',
  borderRadius: '7px',
  p: 2,
  px: 4,
  pb: 3,
};

export default function CardGroup({ config }) {
  const { cols, qPage, sortColumn } = config;
  // console.log('sortColumn', sortColumn);
  const sortCriteria = { qInterColumnSortOrder: [sortColumn] };

  const [open, setOpen] = React.useState(false);
  const [selectedProd, setSelectedProd] = React.useState();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { select, selectValues } = useButton();

  const handleModalOpen = (ProdCode) => {
    const { text } = ProdCode;
    handleOpen();
    select(text, '[Prod Colour Code]');
    setSelectedProd(text);
  };

  const handleModalClose = () => {
    handleClose();
    selectValues([selectedProd], '[Prod Colour Code]', true);
  };

  const { dataSet } = useTable({
    cols,
    qPage,
    sortCriteria,
  });

  return (
    <>
      <Box
        sx={{
          display: 'grid',
          gap: 3,
          gridTemplateColumns: {
            xs: 'repeat(1, 1fr)',
            sm: 'repeat(2, 1fr)',
            md: 'repeat(3, 1fr)',
            lg: 'repeat(4, 1fr)',
          },
        }}
      >
        {(dataSet || [...Array(12)]).map((product, index) =>
          product ? (
            <Card key={index} product={product} handleModalOpen={handleModalOpen} />
          ) : (
            <SkeletonProductItem key={index} />
          )
        )}
      </Box>

      <StyledModal
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={open}
        onClose={handleModalClose}
        BackdropComponent={Backdrop}
      >
        <Box sx={style}>
          <Table
            title={'Sales by Location'}
            cols={[
              {
                qField: 'Location_name',
                dataKey: 'Location',
                qLabel: 'Location',
              },
              {
                qField: '=dateOfFirstSale',
                dataKey: 'First Sale',
                qLabel: 'First Sale',
              },
              {
                // eslint-disable-next-line
                qField: '=sum(${ValueMetric})',
                dataKey: 'Sales Value',
                qLabel: 'Sales Value',
              },
              {
                // eslint-disable-next-line
                qField: '=sum(${VolumeMetric})',
                dataKey: 'Sales Volume',
                qLabel: 'Sales Volume',
              },
              {
                // eslint-disable-next-line
                qField: '=currentOnHand',
                dataKey: 'On Hand',
                qLabel: 'On Hand',
              },
            ]}
            qPage={{ qWidth: 15, qHeight: 600 }}
            initialSortKey={'Sales Value'}
            initialColumnsToExclude={['varianceText']}
            disableVariance
            disableMetric
          />
        </Box>
      </StyledModal>
    </>
  );
}
