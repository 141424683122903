import numeral from 'numeral';

numeral.register('locale', 'fr', {
  delimiters: {
    thousands: ',',
    decimal: '.',
  },
  abbreviations: {
    thousand: 'K',
    million: 'M',
    billion: 'B',
    trillion: 'T',
  },
  currency: {
    symbol: '€',
  },
});

numeral.locale('fr');

// ----------------------------------------------------------------------

export function fCurrency(number) {
  return numeral(number).format(Number.isInteger(number) ? '$0,0a' : '$0,0.0a');
}

export function fPercent(number) {
  return numeral(number).format('0,0%');
}

export function fPercentVar(number) {
  if (number === 'NaN') {
    return 'nm';
  }

  return numeral(number).format('+0,0%;-0,0%');
}

export function fPercentPointVar(number) {
  if (number === 'NaN') {
    return 'nm';
  }

  return `${number > 0 ? '+' : ''}${Math.round(number * 100)}pp`;
}

export function fNumber(number) {
  return numeral(number).format('0a');
}

export function fShortenNumber(number) {
  return numeral(number).format('0.00a').replace('.00', '');
}

export function fData(number) {
  return numeral(number).format('0.0 b');
}

export function fValueVolume(number, metric) {
  return metric ? fCurrency(number) : fNumber(number);
}
