import { useTheme } from '@mui/material/styles';

// inverse applies to pending where the lower the number the better it is

export function LinearProgressColor(value, inverse = false) {
  const theme = useTheme();

  if (inverse ? value < 66 : value > 66) {
    return { name: 'success', hex: theme.palette.success.main };
  }

  if (inverse ? value < 66 : value > 33) {
    return { name: 'warning', hex: theme.palette.warning.main };
  }

  return { name: 'error', hex: theme.palette.error.main };
}
