// deployed

const appIDs = {
  testing: '45690f72-45d1-46d4-9c60-954cd1724858',
};

export default {
  host: '192.168.101.13',
  secure: true,
  port: 19077,
  prefix: 'autone',
  // the testing app BELOW
  appId: appIDs.testing,
  // redirectFileName: 'autone_local_direct.html',
  redirectFileName: 'autone_supplyflow_performance_redirect.html',
  qsServerType: 'onPrem',
};
