import { LinearProgress, Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { fText } from './formatText';
import { fPercentVar, fValueVolume, fPercent, fPercentPointVar } from './formatNumber';
import Label from '../components/Label';
import { LinearProgressColor } from './formatBarColor';

// ----------------------------------------------------------------------

const ThumbImgStyle = styled('img')(({ theme }) => ({
  width: 74,
  height: 74,
  objectFit: 'cover',
  margin: theme.spacing(0, 2),
  borderRadius: theme.shape.borderRadius,
}));

function LinearProgressWithLabel(props) {
  // eslint-disable-next-line
  const { value, inverse } = props;
  const barColor = LinearProgressColor(value, inverse).name;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress color={barColor} variant="determinate" value={value} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(value)}%`}</Typography>
      </Box>
    </Box>
  );
}

const cover = 'https://d1j9x9sbg3f3rb.cloudfront.net/standard_no_image.jpg';

export function fQlikTableCell({ text, number, columnId }, dataIndex, isMetric) {
  if (dataIndex.toLowerCase().includes('percbar')) {
    return number === 'NaN' ? (
      'nm'
    ) : (
      <LinearProgressWithLabel value={number * 100} inverse={dataIndex.toLowerCase().includes('pending')} />
    );
  }

  if (text.includes('SPLIT')) {
    const splitValues = text.split('SPLIT');

    return (
      <>
        <Box
          sx={{
            py: 2,
            pl: 0,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <ThumbImgStyle alt="test" src={splitValues[2].includes('ngg-b2b') ? splitValues[2] : cover} />

          <Box
            sx={{
              py: 2,
              display: 'block',
            }}
          >
            <Typography component="div" variant="body2">
              {splitValues[1]}
            </Typography>

            <Typography component="div" variant="body3" sx={{ color: '#637381' }}>
              {splitValues[0]}
            </Typography>
          </Box>
        </Box>
      </>
    );
  }

  if (number === 'NaN' || columnId === 0 || dataIndex.toLowerCase().includes('first sale')) {
    return fText(text);
  }

  if (dataIndex.toLowerCase().includes('value')) {
    return fValueVolume(number, true);
  }

  if (dataIndex.toLowerCase().includes('mix')) {
    return fPercent(number);
  }

  if (dataIndex.toLowerCase().includes('volume')) {
    return fValueVolume(number, false);
  }

  let displayNumber;

  if (dataIndex.includes('%')) {
    displayNumber = fPercentVar(number);
  } else if (dataIndex.includes('PP')) {
    displayNumber = fPercentPointVar(number);
  } else {
    displayNumber = fValueVolume(number, isMetric);
  }

  if (dataIndex.includes('vs')) {
    return <Label color={number > 0 ? 'success' : 'error'}>{displayNumber}</Label>;
  }

  return displayNumber;
}
